
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import DriverSet from '@/components/DriverSet.vue'
import PageUtil from "@/utils/page_util";
import {IMsgRecordItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";
import ApiAdmin from "@/request/apis/api_admin";
import {IAdminInfo} from "@/models/user_models";

interface IState {
  list: any[],
  loading: boolean;
  adminList: IAdminInfo[];
  adminId: any;
  visible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: '姓名',
    dataIndex: 'admin',
    key: 'name',
  },
  {
    title: '电话',
    dataIndex: 'admin',
    key: 'mobile',
  },
  {
    title: '新增时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '操作',
    key: 'action',
  },
];

export default defineComponent({
  name: 'PlatService',
  components: {ImageView, DriverSet},
  setup() {
    const state: IState = reactive({
      list: [],
      loading: false,
      adminList: [],
      adminId: undefined,
      visible: false
    })

    //获取列表
    const getList = async () => {
      state.loading = true
      const res = await ApiLogistics.getServiceList()
      state.loading = false
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    //获取管理员列表
    const getAdminList = async () => {
      state.loading = true
      const res = await ApiAdmin.getAdminList({page: 1, limit: 500})
      state.loading = false
      if (res && res.error_code === 0) {
        state.adminList = res.data?.lists || []
      }
    }

    const setVisible = (flg: boolean) => {
      if (flg) {
        state.adminId = ''
      }
      state.visible = flg
    }

    const renderRoles = (admin: IAdminInfo) => {
      let roles = '';
      admin.roles?.map(e => {
        roles += e.role?.name + ','
      })

      return roles.substr(0, roles.length - 1)
    }

    // 新增
    const addService = async () => {
      if (!state.adminId) {
        message.destroy()
        message.warning('请选择管理员')
        return
      }
      state.loading = true
      const res = await ApiLogistics.addService(state.adminId)
      state.loading = false
      message.destroy()
      if (res && res.error_code === 0) {
        message.success('新增成功')
        setVisible(false)
        await getList()
        return
      }
      message.error(res.msg || '新增失败')

    }

    const onDel = async (item: any) => {
      state.loading = true
      const res = await ApiLogistics.onDelService(item.id)
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('删除成功')
        await getList()
        return
      }
      message.error(res.msg || '删除失败')
    }

    onMounted(() => {
      getList()
      getAdminList();
    })

    return {
      ...toRefs(state),
      columns,
      dateFormat,
      setVisible,
      renderRoles,
      addService,
      onDel,
    }
  }
})
